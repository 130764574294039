import { amountToPriceString } from '@caterdesk/utils--money'
import { Divider, Stack, Typography } from '@mui/material'
import type { VendorsGmQuery } from '@/generated/graphql'
import type React from 'react'
import { Fragment, useMemo } from 'react'
import { getVendorLocationDetails } from './functions'

export type Props = {
  vendor: NonNullable<VendorsGmQuery['vendors']['rows']>[number]
}

const LocationDetails: React.FC<Props> = ({ vendor }) => {
  const vendorLocationDetails = useMemo(() => getVendorLocationDetails(vendor), [vendor])

  if (!vendorLocationDetails) {
    return null
  }

  return (
    <Fragment>
      <Stack flexDirection="row" justifyContent="space-evenly">
        <Typography variant="body2">
          {amountToPriceString(
            vendor.currency || 'GBP',
            vendorLocationDetails?.deliveryFee || 0,
            true,
          )}{' '}
          <Typography variant="caption" color="text.secondary">
            Delivery Fee
          </Typography>
        </Typography>

        <Typography variant="body2">
          {amountToPriceString(
            vendor.currency || 'GBP',
            vendorLocationDetails?.minOrderFee || 0,
            true,
          )}{' '}
          <Typography variant="caption" color="text.secondary">
            Minimum Order
          </Typography>
        </Typography>
      </Stack>

      <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
    </Fragment>
  )
}
export default LocationDetails
