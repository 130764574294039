import type { VendorsGmQuery } from '@/generated/graphql'
import { ServiceType } from '@/generated/graphql'

type Vendor = NonNullable<VendorsGmQuery['vendors']['rows']>[number]

type DeliveryRegion = Vendor['locations'][number]['deliveryRegions'][number]

export function getVendorLocationDetails(vendor: Vendor) {
  if (vendor.locations.length === 0) {
    return null
  }

  const defaultLocation = vendor.locations.find((location) => {
    return location.isDefault
  })

  if (!defaultLocation) {
    return null
  }

  const priorityLocation = defaultLocation.deliveryRegions.reduce(
    (accumulator: DeliveryRegion | null, currentValue) => {
      if (currentValue.serviceType !== ServiceType.Gm) {
        return accumulator
      }

      if (!accumulator || accumulator == null) {
        return currentValue
      }

      return accumulator.orderPosition < currentValue.orderPosition ? accumulator : currentValue
    },
    null,
  )

  return {
    deliveryFee: priorityLocation?.deliveryFee?.totalExTax || null,
    minOrderFee: priorityLocation?.minOrderValueExTax || null,
  }
}
